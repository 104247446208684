import { toast } from 'react-toastify';
import { NETWORK_STATUS_TOASTS } from '@/toast-notifications/constants/Constants';

export default function checkOfflineNetworkError() {
  if (!navigator.onLine) {
    toast.error(
      'Unable to send your request. Please check your internet connection and try again.',
      {
        toastId: NETWORK_STATUS_TOASTS.offlineRequest,
      },
    );
  }
}
